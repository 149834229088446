import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GetResult, Preferences } from '@capacitor/preferences';
import { BehaviorSubject } from 'rxjs';
import { jwtDecode, JwtPayload } from "jwt-decode";
import { UserInterface } from '@kiddy-cash/common';
import { Badge } from '@capawesome/capacitor-badge';
import { AlertController, ModalController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';

interface AuthData {
  phone: string;
}

interface ApiResponse {
  message: string;
  data: {
    code: string;
  }
}

interface OtpData {
  phone: string;
  code: number;
}

interface RegisterPhoneData {
  phone: string;
  pin: number;
  confirmPin: number;
}

interface VerifyOtpResponse {
  message: string;
  data: {
    token: string;
  }
}

interface LoginData {
  phone?: string;
  email?:string;
  code: number;
}

interface ValidateLoginResponse {
  data: UserData;
  message: string;
}

interface UserData {
  token: string;
  user: {
    email: string;
    phone: string;
    status: string;
    creation_date: string;
    updated_on: string;
    profile: any
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public _$user = new BehaviorSubject<UserInterface | null | undefined>(undefined);
  public _$token = new BehaviorSubject<string | null | undefined>(undefined);

  constructor(
    private http: HttpClient,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
  ) { }

  generateOTP(data: AuthData, phoneSignUp:boolean) {
    return phoneSignUp ? this.http.post<ApiResponse>(`${environment.authBaseUrl}/register/phone-get-otp`, data) : this.http.post<ApiResponse>(`${environment.authBaseUrl}/register/email-get-otp`, data);
  }

  verifyOTP(data: OtpData, phoneSignUp: boolean) {
    return phoneSignUp ? this.http.post<VerifyOtpResponse>(`${environment.authBaseUrl}/register/phone-validate-otp`, data) : this.http.post<VerifyOtpResponse>(`${environment.authBaseUrl}/register/email-validate-otp`, data);
  }

  registerUser(registerUserData: any, phoneSignUp:boolean) {
    return phoneSignUp ? this.http.post(`${environment.authBaseUrl}/register/phone`, registerUserData) : this.http.post(`${environment.authBaseUrl}/register/email`, registerUserData);;
  }

  generateLoginOTP(data: LoginData, phoneLogin: boolean) {
    return phoneLogin ? this.http.post<ApiResponse>(`${environment.authBaseUrl}/login/phone-get-otp`, data) : this.http.post<ApiResponse>(`${environment.authBaseUrl}/login/email-get-otp`, data);
  }

  validateLoginOTP(data: LoginData, phoneLogin: boolean) {
    return phoneLogin ? this.http.post(`${environment.authBaseUrl}/login/phone-validate-otp`, data) : this.http.post<ApiResponse>(`${environment.authBaseUrl}/login/email-validate-otp`, data);
  }

  requestPinResetPhoneOTP(payload: any) {
    return this.http.post(`${environment.authBaseUrl}/pin/phone-get-otp`, payload);
  }

  verifyPinResetPhoneOTP(phone: string, code: number) {
    return this.http.post(`${environment.authBaseUrl}/pin/phone-validate-otp`, 
      {
        phone: phone,
        code: code,        
      }
    );
  }

  confirmPinResetPhoneOTP(phone: string, code: number, pin: string) {
    return this.http.post(`${environment.authBaseUrl}/pin/phone-confirm-otp`, 
      {
        phone: phone,
        code: code,
        pin: pin,
      }
    );
  }

  requestPinResetEmailOTP(payload: any) {
    return this.http.post(`${environment.authBaseUrl}/pin/email-get-otp`, payload);
  }

  verifyPinResetEmailOTP(email: string, code: number) {
    return this.http.post(`${environment.authBaseUrl}/pin/email-validate-otp`, 
      {
        email: email,
        code: code,        
      }
    );
  }

  confirmPinResetEmailOTP(email: string, code: number, pin: string) {
    return this.http.post(`${environment.authBaseUrl}/pin/email-confirm-otp`, 
      {
        email: email,
        code: code,
        pin: pin,
      }
    );
  }

  async getAuthToken() {
    const ret:any = await Preferences.get({ key: 'access_token' });
    const token = ret.value;
    return token
  }

  async logout() {
    await Preferences.remove({key: 'access_token'})
    await Preferences.remove({key: 'fcm_token'})
    await Preferences.remove({key: '__lastActive'})
    this._$user.next(null);
    this._$token.next(null);
    if(Capacitor.isNativePlatform()) await Badge.clear();

    // dismiss any modal if present
    const topModal = await this.modalCtrl.getTop();
    if(topModal) {
      topModal.canDismiss = true;
      await this.modalCtrl.dismiss();
    }

    // dismiss any topAlert if present
    const topAlert = await this.alertCtrl.getTop();
    if(topAlert) await this.alertCtrl.dismiss();
  }

  async checkStoredUser() {
    const storedToken: GetResult = await Preferences.get({ key: 'access_token' });
    const token = storedToken.value;    

    if(!token) {
      this.setUser(null);
      return;
    }

    const decodedToken  = jwtDecode<JwtPayload>(token);
    const isExpired: boolean = decodedToken.exp ? Date.now() >= decodedToken.exp * 1000 : true;
    if (isExpired) {
      await this.logout();
      return;
    }

    this.setToken(token);

    const decodedUser: any = jwtDecode(token);
    const user: UserInterface = decodedUser.user;

    this.setUser(user);
  }

  setUser(user: UserInterface | null | undefined) {
    this._$user.next(user);
  }

  setToken(token: string) {
    this._$token.next(token);
  }

  async storeToken(token: string) {
    await Preferences.set({
      key: 'access_token',
      value: token
    });
    this.setToken(token);
  }

  async decodeAndStoreToken(token: string) {
    await this.storeToken(token);

    const decodedUser: any = jwtDecode(token);
    const user: UserInterface = decodedUser.user;

    this.setUser(user);
  }

}
